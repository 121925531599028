import { useState } from "react";
import "./App.css";
import MainMint from "./MainMint";
import NavBar from "./NavBar";

function App() {
  const [accounts, setAccounts] = useState([]);
  const [minted, setMinted] = useState();

  return (
    <div className="overlay">
      <div className="App">
        <NavBar
          accounts={accounts}
          setAccounts={setAccounts}
          setMinted={setMinted}
        />
        <MainMint accounts={accounts} minted={minted} />
      </div>
      <div className="moving-background"></div>
    </div>
  );
}

export default App;
