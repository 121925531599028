import React from "react";
import { Box, Button, Flex, Image, Link } from "@chakra-ui/react";
import Twitter from "./assets/social-media-icons/twitter.png";
import Discord from "./assets/social-media-icons/discord.png";
import Etherscan from "./assets/social-media-icons/etherscan.png";
import Opensea from "./assets/social-media-icons/opensea.png";
import truncateEthAddress from "truncate-eth-address";
import { ethers } from "ethers";
import GobContract from "./Gob.json";

const gobContractAddress = process.env.REACT_APP_GOB_ADDRESS;

const NavBar = ({ accounts, setAccounts, setMinted }) => {
  const isConnected = Boolean(accounts[0]);
  const chainIdTarget = process.env.REACT_APP_CHAIN_ID;

  async function switchNetwork() {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${chainIdTarget}` }],
    });
  }
  async function getMinted() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        gobContractAddress,
        GobContract.abi,
        provider
      );
      try {
        const tokenId = await contract.nextTokenId();
        console.log(tokenId.sub(1).toString());
        setMinted(tokenId.sub(1).toString());
      } catch (err) {
        console.log("error", err);
      }
    }
  }

  async function requestAccount() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const chainId = (await provider.getNetwork()).chainId;
      if (chainId !== chainIdTarget) {
        await switchNetwork();
      }
      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccounts(accounts[0]);
        await getMinted();
      } catch (error) {
        console.log("Error connecting");
      }
    } else {
      alert("Metamask not detected");
    }
  }

  return (
    <Flex justify="space-between" align="center" padding="30px">
      {/* Lest Side - Social Media Icons */}
      <Flex justify="space-around" width="20%" padding="0 75px">
        <Link href="https://twitter.com/GhostsOnBitcoin">
          <Image src={Twitter} boxSize="42px" margin="0 15px" />
        </Link>
      </Flex>
      <Flex justify="space-around" width="20%" padding="0 75px">
        <Link href="https://t.co/hNaBvOGTPc">
          <Image src={Discord} boxSize="42px" margin="0 15px" />
        </Link>
      </Flex>
      <Flex justify="space-around" width="20%" padding="0 75px">
        <Link href="https://etherscan.io/address/0x005411b5806229e6498df15a6df173bf8fc2be18">
          <Image src={Etherscan} boxSize="42px" margin="0 15px" />
        </Link>
      </Flex>
      <Flex justify="space-around" width="20%" padding="0 75px">
        <Link href="https://opensea.io/fr/collection/ghosts-on-bitcoin">
          <Image src={Opensea} boxSize="48px" margin="0 15px" />
        </Link>
      </Flex>

      {/* Right Side - Sections and Connect */}
      <Flex
        justify="space-around"
        align="center"
        width="20%"
        padding="30px 30px 30px 30px"
      >
        {/* <Box margin="0 15px">About</Box>
        <Spacer /> */}
        {/* <Box margin="0 15px">Mint</Box>
        <Spacer /> */}
        <Box margin="0 20px">Burn (soon)</Box>
      </Flex>

      {/* Connect */}
      {isConnected ? (
        <Box margin="0 -14px">{truncateEthAddress(accounts)}</Box>
      ) : (
        <Button
          backgroundColor="#D6517D"
          borderRadius="5px"
          boxShadow="0px 2px 2px 1px #0F0F0F"
          color="white"
          cursor="pointer"
          fontFamily="inherit"
          padding="15px"
          margin="0 15px"
          onClick={requestAccount}
        >
          Connect
        </Button>
      )}
    </Flex>
  );
};

export default NavBar;
