import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import GobContract from "./Gob.json";

const gobContractAddress = process.env.REACT_APP_GOB_ADDRESS;

const MainMint = ({ accounts, minted }) => {
  const [mintAmount, setMintAmount] = useState(1);
  const isConnected = Boolean(accounts[0]);

  async function handleMint() {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        gobContractAddress,
        GobContract.abi,
        signer
      );
      try {
        const price = await contract.price();
        const response = await contract.mintPublic(mintAmount, {
          value: price.mul(mintAmount),
        });
        console.log("response:", response);
      } catch (err) {
        console.log("error", err);
      }
    }
  }

  const handleDecrement = () => {
    if (mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  };
  const handleIncrement = () => {
    if (mintAmount >= 3) return;
    setMintAmount(mintAmount + 1);
  };
  useEffect(() => {}, [mintAmount]);

  return (
    <Flex justify="center" align="center" height="100vh" paddingBottom="300px">
      <Box width="520px">
        <div>
          <Text fontSize="40px" textShadow="0 5px #000000">
            Ghosts on Bitcoin
          </Text>
        </div>
        {isConnected ? (
          <div>
            <Flex align="center" justify="center">
              <Button
                backgroundColor="#D6517D"
                borderRadius="5px"
                boxShadow="0px 2p 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                marginTop="10px"
                onClick={handleDecrement}
              >
                -
              </Button>
              <Input
                readOnly
                fontFamily="inherit"
                width="100px"
                height="40px"
                textAlign="center"
                marginTop="10px"
                value={mintAmount}
              />
              <Button
                backgroundColor="#D6517D"
                borderRadius="5px"
                boxShadow="0px 2p 2px 1px #0F0F0F"
                color="white"
                cursor="pointer"
                fontFamily="inherit"
                padding="15px"
                marginTop="10px"
                onClick={handleIncrement}
              >
                +
              </Button>
            </Flex>
            <Text
              marginBottom="-40px"
              fontSize="20px"
              textShadow="0 5px #000000"
            >
              {minted} / 50
            </Text>
            <Button
              backgroundColor="#D6517D"
              borderRadius="5px"
              boxShadow="0px 2p 2px 1px #0F0F0F"
              color="white"
              cursor="pointer"
              fontFamily="inherit"
              padding="15px"
              marginTop="80px"
              marginBottom="-90px"
              onClick={handleMint}
            >
              Mint Now
            </Button>
          </div>
        ) : (
          <Text
            marginTop="200px"
            marginBottom="-90px"
            fontSize="30px"
            letterSpacing="-5.5%"
            fontFamily="VT323"
            textShadow="0 3px #000000"
            color="#D6517D"
          >
            You must be connected to Mint.
          </Text>
        )}
      </Box>
    </Flex>
  );
};
export default MainMint;
